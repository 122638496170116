import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';

import { LanguageContext } from './LanguageContext';

import { ReactComponent as SocialIconFacebook } from 'assets/socials/facebook.svg';
import { ReactComponent as SocialIconTwitter } from 'assets/socials/twitter.svg';
import { ReactComponent as SocialIconYoutube } from 'assets/socials/youtube.svg';
import { ReactComponent as SocialIconInstagram } from 'assets/socials/instagram.svg';

const Footer = () => {
	const { dictionary } = useContext(LanguageContext);
	const year = new Date().getFullYear();

	return (
		<StyledFooter className="u-bg-black">
			<Container fluid>
				<Row className="pt-5 pb-5 p-4">
					<Col sm={12} lg={4} className="col-contacts">
						<div className="mb-3 text-center text-lg-start">
							<a
								rel="noopener noreferrer"
								href="mailto:info@kinetik-e.com"
								target="_blank"
								className="text-break"
							>
								info@kinetik-e.com
							</a>
						</div>

						<div className="text-center text-lg-start">
							<a href="tel:+359882023636" className="text-white">
								+359 882 023 636
							</a>
						</div>
					</Col>

					<Col
						sm={12}
						lg={4}
						className="d-flex justify-content-center align-items-center col-copyright"
					>
						<div className="mb-0 text-white text-center pt-4 pt-lg-0 pb-4 pb-lg-0">
							© {year} {dictionary.footer.text}
						</div>
					</Col>

					<Col
						sm={12}
						lg={4}
						className="d-flex justify-content-center justify-content-lg-end align-items-center col-social"
					>
						<SocialIcon
							rel="noopener noreferrer"
							href="https://www.facebook.com/kinetikautomotive/"
							target="_blank"
							className="m-1"
						>
							<SocialIconFacebook />
						</SocialIcon>

						<SocialIcon
							rel="noopener noreferrer"
							href="https://twitter.com/kinetik_auto"
							target="_blank"
							className="m-1"
						>
							<SocialIconTwitter />
						</SocialIcon>

						<SocialIcon
							rel="noopener noreferrer"
							href="https://www.youtube.com/channel/UCjZc8FKtn9K2orBw7QfOOGw"
							target="_blank"
							className="m-1"
						>
							<SocialIconYoutube />
						</SocialIcon>

						<SocialIcon
							rel="noopener noreferrer"
							href="https://www.instagram.com/kinetik_automotive/"
							target="_blank"
							className="m-1"
						>
							<SocialIconInstagram />
						</SocialIcon>
					</Col>
				</Row>
			</Container>
		</StyledFooter>
	);
};

const StyledFooter = styled.footer`
	@media ${props => props.theme.breakpoints.md} {
		[class*='col-'] {
			padding-right: 0 !important;
			padding-left: 0 !important;
		}

		.col-contacts {
			order: 1;
			padding-bottom: 32px;
		}

		.col-social {
			order: 2;
			padding-bottom: 12px;
		}

		.col-copyright {
			order: 3;
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		font-size: 15px;
	}
`;

const SocialIcon = styled.a`
	color: ${props => props.theme.colors.primary};
	width: 42px;
	height: 42px;
	display: block;
	border: 1px solid #fff;
	background-color: #fff;
	transition: color 0.3s ease-in-out;

	svg {
		fill: currentColor;
		transform: scale(1);
		transition: transform 0.3s ease-in-out;
	}

	&:hover {
		color: ${props => props.theme.colors.secondary};

		svg {
			transform: scale(1.2);
		}
	}
`;

export default Footer;
