import React from 'react';

import Header from 'components/Header';

import Top from './Top';
import AboutElKarts from './AboutElKarts';
import WhyDrive from './WhyDrive';
import RaceCal from './RaceCal';
import AboutUs from './AboutUs';
import Team from './Team';
import Sponsors from './Sponsors';
import Testimonials from './Testimonials';
// import TestDrive from './TestDrive';
import ParticipationFee from './ParticipationFee';
import Subscribe from './Subscribe';
import FAQ from './FAQ';
import Footer from 'components/Footer';

const Home = () => (
	<>
		<Header />
		<Top />
		<AboutElKarts />
		<WhyDrive />
		<RaceCal />
		<AboutUs />
		<Team />
		<Sponsors />
		<Testimonials />
		{/* <TestDrive /> */}
		<ParticipationFee />
		<Subscribe />
		<FAQ />
		<Footer />
	</>
);

export default Home;
