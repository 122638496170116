import React, { useContext } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import RegForm from 'routes/home/RegForm';

import CModal from './Modal';
import Button from './Button';
import LanguageSelector from './LanguageSelector';
import { LanguageContext } from './LanguageContext';

import { ReactComponent as BrandImage } from 'assets/icons/kes-logo.svg';
import close from 'assets/icons/icon-close.svg';
import hamburger from 'assets/icons/hamburger.svg';

const Header = () => {
	const { dictionary } = useContext(LanguageContext);

	const RegisterButton = () => (
		<CModal content={<RegForm />} className="c-modal--reg">
			<Button className="btn-lg text-uppercase text-center">
				{dictionary.register.registerBtn}
			</Button>
		</CModal>
	);

	return (
		<StyledHeader>
			<Container fluid>
				<Row>
					<Col
						xs={6}
						lg={2}
						className="u-bg-black d-flex align-items-center justify-content-start justify-content-lg-center"
					>
						<a href="#top" className="text-center">
							<BrandImage className="logo" />
						</a>
					</Col>

					<Col
						xs={6}
						lg={8}
						className="c-header d-flex justify-content-end justify-content-lg-center p-2"
					>
						<Navbar expand="lg" collapseOnSelect>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />

							<Navbar.Collapse>
								<Nav>
									<Nav.Link
										href="#ego-karts"
										className="text-white"
									>
										{dictionary.header.menu.eGoKarts}
									</Nav.Link>

									<Nav.Link
										href="#race-cal"
										className="text-white"
									>
										{dictionary.header.menu.raceCalendar}
									</Nav.Link>

									<Nav.Link
										href="#about-us"
										className="text-white"
									>
										{dictionary.header.menu.aboutUs}
									</Nav.Link>

									<Nav.Link
										href="#testimonials"
										className="text-white"
									>
										{dictionary.header.menu.testimonials}
									</Nav.Link>

									<Nav.Link
										href="#newsletter"
										className="text-white"
									>
										{dictionary.header.menu.newsletter}
									</Nav.Link>

									<Nav.Link
										href="#faq"
										className="text-white"
									>
										{dictionary.header.menu.faq}
									</Nav.Link>
								</Nav>

								<div className="mobile-reg-button u-bg-primary d-lg-none">
									<RegisterButton />
								</div>

								<LanguageSelector />
							</Navbar.Collapse>
						</Navbar>
					</Col>

					<Col lg={2} className="d-none d-lg-flex p-0 button-col">
						<RegisterButton />
					</Col>
				</Row>
			</Container>
		</StyledHeader>
	);
};

const StyledHeader = styled.header`
	position: fixed;
	width: 100%;
	z-index: 100;

	.c-header {
		background: rgba(0, 0, 0, 0.5);

		@media ${props => props.theme.breakpoints.md} {
			background: rgba(0, 0, 0, 1);
		}

		.navbar-toggler {
			.navbar-toggler-icon {
				width: 24px;
				height: 24px;
				background: url(${close}) no-repeat center center;
				background-size: 24px 24px;
			}
		}

		.navbar-toggler.collapsed {
			.navbar-toggler-icon {
				width: 24px;
				height: 24px;
				background: url(${hamburger}) no-repeat center center;
				background-size: 24px 24px;
			}
		}
	}

	.logo {
		width: 60%;
		height: auto;
		display: block;
		fill: #fff;
		margin: auto;
	}

	.button-col {
		> div,
		> div button {
			width: 100%;
			height: 100%;
		}

		> div button {
			justify-content: center;
		}
	}

	@media ${props => props.theme.breakpoints.md} {
		.navbar-toggler {
			position: relative;
			z-index: 201;
		}

		.navbar-nav {
			padding-bottom: 1rem;

			a {
				margin: 0.25rem 0;
			}
		}

		.navbar-collapse {
			text-align: center;
			height: 100% !important;
			flex-flow: column wrap;
			align-items: center;
			justify-content: center;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 200;
			background: #000;
			transition: none !important;

			&.show,
			&.collapsing {
				display: flex;
			}
		}

		.mobile-reg-button {
			padding: 0.625rem 0.75rem 0.5rem;
		}

		.logo {
			width: auto;
			height: 30px;
		}
	}
`;

export default Header;
