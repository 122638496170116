import React, { useState, useEffect, useContext } from 'react';
import Form from 'react-bootstrap/Form';

import Button from 'components/Button';
import { LanguageContext } from 'components/LanguageContext';

const SignUpCustom = ({ status, message, onValidated }) => {
	const {
		dictionary: { signUp }
	} = useContext(LanguageContext);

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');

	const handleSubmit = e => {
		e.preventDefault();
		email &&
			name &&
			email.indexOf('@') > -1 &&
			onValidated({
				EMAIL: email,
				MERGE1: name
			});
	};

	useEffect(() => {
		if (status === 'success') clearFields();
	}, [status]);

	const clearFields = () => {
		setName('');
		setEmail('');
	};

	return (
		<Form onSubmit={e => handleSubmit(e)}>
			{status === 'sending' && (
				<div className="mc__alert mc__alert--sending mb-3">
					{signUp.formSending}
				</div>
			)}

			{status === 'error' && (
				<div
					className="mc__alert mc__alert--error mb-3"
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}

			{status === 'success' && (
				<div
					className="mc__alert mc__alert--success text-primary"
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}

			{status !== 'success' ? (
				<>
					<Form.Group className="mb-4" controlId="formBasicName">
						<Form.Control
							onChange={e => setName(e.target.value)}
							type="text"
							value={name}
							placeholder={signUp.formFieldName}
							required
						/>
					</Form.Group>

					<Form.Group className="mb-4" controlId="formBasicEmail">
						<Form.Control
							onChange={e => setEmail(e.target.value)}
							type="email"
							value={email}
							placeholder={signUp.formFieldEmail}
							required
						/>
					</Form.Group>

					<div className="mb-4">
						<Button
							type="submit"
							formvalues={[email, name]}
							className="btn--hover-shadow-white"
						>
							{signUp.formBtn}
						</Button>
					</div>
				</>
			) : null}
		</Form>
	);
};

export default SignUpCustom;
