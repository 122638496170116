import en from './en';
import bg from './bg';

import flagBg from 'assets/icons/flag-bg.svg';
import flagGb from 'assets/icons/flag-gb.svg';

export const dictionaryList = {
	en,
	bg
};

export const languageOptions = [
	{
		id: 'en',
		text: 'English',
		icon: flagGb
	},
	{
		id: 'bg',
		text: 'Български',
		icon: flagBg
	}
];
