import React, { useState, createContext, useContext, useEffect } from 'react';

import { languageOptions, dictionaryList } from 'languages';

/*
 * Ref:
 * Create a Multi-Language Website with React Context API
 * https://dev.to/halilcanozcelik/create-a-multi-language-website-with-react-context-api-4i27
 */

// Create the language context with default selected language
export const LanguageContext = createContext({
	language: languageOptions[0],
	dictionary: dictionaryList[languageOptions[0].id]
});

// Provide the language context to app
export function LanguageProvider(props) {
	const languageContext = useContext(LanguageContext);
	const [language, setLanguage] = useState(languageContext.language);
	const [dictionary, setDictionary] = useState(languageContext.dictionary);

	const provider = {
		language,
		dictionary,
		setLanguage: selectedLanguage => {
			setLanguage(selectedLanguage);
			setDictionary(dictionaryList[selectedLanguage.id]);
		}
	};

	useEffect(() => {
		document.documentElement.setAttribute('lang', language.id);
	}, [language]);

	return (
		<LanguageContext.Provider value={provider}>
			{props.children}
		</LanguageContext.Provider>
	);
}

// Get text according to id & current language
export function Text(props) {
	const languageContext = useContext(LanguageContext);

	return languageContext.dictionary[props.tid];
}
