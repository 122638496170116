import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

import Pattern from 'components/Pattern';
import SignUpForm from './SignUpForm';
import { LanguageContext } from 'components/LanguageContext';

import subscribeBg from 'assets/subscribe.jpg';

const Subscribe = () => {
	const {
		dictionary: { signUp }
	} = useContext(LanguageContext);

	return (
		<SubscribeSection id="newsletter">
			<Container fluid className="pt-4 pb-4 pt-lg-5 pb-lg-5">
				<Row>
					<Col sm={12} lg={6} xl={5} className="g-md-0 mt-5 mb-5">
						<Fade direction="left">
							<ContentWrap className="p-5 text-white">
								<Pattern
									x="right"
									y="top"
									name="SubscribeTopRight"
								/>

								<h2 className="mt-3 mt-lg-4 mb-3 mb-lg-4">
									{signUp.title}
								</h2>

								<h5 className="mt-3 mt-lg-4 mb-3 mb-lg-4">
									{signUp.subtitle}
								</h5>

								<div>{signUp.text}</div>

								<div className="mt-5">
									<SignUpForm />
								</div>
							</ContentWrap>
						</Fade>
					</Col>
				</Row>
			</Container>
		</SubscribeSection>
	);
};

const SubscribeSection = styled.section`
	background: url(${subscribeBg}) no-repeat center center;
	background-size: cover;
`;

const ContentWrap = styled.div`
	position: relative;
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));

	@media ${props => props.theme.breakpoints.xs} {
		padding: 1.5rem !important;
	}
`;

export default Subscribe;
