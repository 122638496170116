import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import RegCustom from './RegCustom';

const RegForm = ({ testDriveOverwrite }) => (
	<div className="mc__form-container">
		<MailchimpSubscribe
			url="https://kinetik-e.us14.list-manage.com/subscribe/post-json?u=1adff54b4126619a4f7e11a67&id=fdf8cfbbbb"
			render={({ subscribe, status, message }) => (
				<RegCustom
					status={status}
					message={message}
					onValidated={formData => subscribe(formData)}
					testDriveOverwrite={testDriveOverwrite}
				/>
			)}
		/>
	</div>
);

export default RegForm;
