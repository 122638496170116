import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { languageOptions } from 'languages';
import { LanguageContext } from './LanguageContext';

export default function LanguageSelector() {
	const [active, setActive] = useState(false);
	const languageContext = useContext(LanguageContext);
	const { language, setLanguage } = languageContext;

	const handleLanguageChange = selectedOption => {
		const selectedLanguage = languageOptions.find(
			item => item.id === selectedOption
		);

		// set selected language by calling context method
		setLanguage(selectedLanguage);
		setActive(false);
	};

	const selectedLanguage = languageOptions.find(
		({ id }) => id === language.id
	);

	const className = 'd-flex justify-content-center align-items-center';

	return (
		<LangDropdown className={`${className}${active ? ' active' : ''}`}>
			<button
				type="button"
				onClick={() => setActive(!active)}
				className={className}
			>
				<img src={selectedLanguage.icon} alt={selectedLanguage.text} />
			</button>

			<ul>
				{languageOptions.map(({ id, icon, text }, i) => (
					<li key={i}>
						<button
							type="button"
							onClick={() => handleLanguageChange(id)}
							disabled={id === language.id}
							className={className}
						>
							<img src={icon} alt={text} />

							{text}
						</button>
					</li>
				))}
			</ul>
		</LangDropdown>
	);
}

const LangDropdown = styled.div`
	color: #fff;
	position: relative;

	button {
		color: inherit;
		padding: 1rem;
		border: 0;
		margin: 0;
		background: none;
		border-radius: 0;
		box-shadow: none;
		appearance: none;

		img {
			width: 32px;
			height: 24px;
			display: block;
		}

		&[disabled] {
			opacity: 0.5;
		}
	}

	ul {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 100%;
		left: 0;
		padding: 0.5rem 0;
		margin: 0.5rem 0 0;
		list-style: none outside none;
		background: rgba(0, 0, 0, 0.5);
		transform: scaleY(0);
		transform-origin: 0 0;
		transition: all 0.3s ease-in-out;

		button {
			padding: 0.5rem 1rem;

			img {
				margin-right: 1rem;
			}
		}
	}

	&.active {
		ul {
			opacity: 1;
			visibility: visible;
			transform: scaleY(1);
		}
	}

	@media ${props => props.theme.breakpoints.md} {
		> button {
			display: none !important;
		}

		ul {
			font-size: 0;
			line-height: 0;
			text-indent: -100%;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			opacity: 1;
			visibility: visible;
			position: static;
			top: auto;
			right: auto;
			left: auto;
			margin-top: 1rem;
			transform: none;

			button img {
				margin: 0;
			}
		}
	}
`;
