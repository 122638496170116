import { keyframes } from 'styled-components';
import { createGlobalStyle } from 'styled-components/macro';

const fade = keyframes`
  0%,
  100% {
    fill: #000;
  }

  50% {
      fill: rgba(0, 0, 0, 0.2);
  }
`;

export default createGlobalStyle`
  a {
    text-decoration: none !important;
  }

  p {
    font-family: Montserrat, sans-serif;
  }

  input.form-control {
    color: #fff;
    background: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    border-radius: 0;
    padding: 8px 0;

    &:focus {
      color: #fff;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #fff;
      outline: 0;
      box-shadow: none;
    }
  }

  .form-select {
    background: transparent;
    color: #fff;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    padding: 8px 0;
    cursor: pointer;

    option {
      color: #000;
    }

    &:focus {
      color: #fff;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #fff;
      outline: 0;
      box-shadow: none;
    }
  }

  .u-width--80 {
    width: 80px;
  }

  .u-bg-primary {
    background: #13E780;
  }

  .u-bg-black {
    background: #000;
  }

  .u-bg-black--opacity {
    background: rgba(0, 0, 0, .5);
  }

  .u-tx--large {
    font-size: 24px;

    @media ${props => props.theme.breakpoints.lg} {
      font-size: 20px;
    }
  }

  .u-tx--huge {
    font-size: 48px;

    @media ${props => props.theme.breakpoints.lg} {
      font-size: 36px;
    }
  }

  .u-tx--half-opacity {
    opacity: .5;
  }

  .btn {
    font-size: 16px;
    line-height: 1.25;
    color: #000;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    border: 0;
    background: ${props => props.theme.colors.primary};
    border-radius: 0;
    box-shadow: none;
    appearance: none;
    transition: all .3s ease-in-out;

    span {
      width: 12px;
      margin: 0 0 0 16px;
      transition: margin 0.3s ease-in-out;
    }

    svg {
      max-width: none !important;
      fill: #000;
      display: block;
      margin: auto !important;
      transition: margin .3s ease-in-out;

      g {
        &.two {
          transition: 0.4s;
          transform: translateX(-11px);
        }

        &.three {
          transition: 0.5s;
          transform: translateX(-22px);
        }
      }
    }

    &--shadow-white {
      box-shadow: 3px 3px 0 #fff;
    }

    &--shadow-black {
      box-shadow: 3px 3px 0 #000;
    }

    &--shadow-white,
    &--hover-shadow-white {
      &:hover {
        box-shadow: 4px 4px 0 #fff;
      }
    }

    &--shadow-black,
    &--hover-shadow-black {
      &:hover {
        box-shadow: 4px 4px 0 #000;
      }
    }

    &:focus {
      box-shadow: none !important;
    }

    &-sm {
      font-size: 14px;
      padding: 9px 12px;
    }

    &-lg {
      font-size: 18px;
      padding: 12px 20px;
    }

    &:hover {
      background: ${props => props.theme.colors.primary};

      span {
        margin: 0 24px 0 16px;
      }

      svg {
        g {
          animation-name: ${fade};
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-delay: 0.2s;

          &.two {
            animation-delay: 0.4s;
          }

          &.three {
            animation-delay: 0.6s;
          }

          &.two,
          &.three {
            transform: translateX(0);
          }
        }
      }
    }

    &--reversed {
      flex-direction: row-reverse;

      span {
        margin: 0 16px 0 0;
      }

      svg {
        position: relative;
        transform: scaleX(-1);
        left: -22px;
      }

      &:hover {
        span {
          margin: 0 16px 0 24px;
        }
      }
    }
  }
`;
