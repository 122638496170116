import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import Container from 'react-bootstrap/Container';

import RaceTrack from 'components/RaceTrack';
import SingleRace from './SingleRace';
import { LanguageContext } from 'components/LanguageContext';

import { tracksData } from 'utils/tracksData';

const RaceCal = () => {
	const {
		dictionary: { raceCal }
	} = useContext(LanguageContext);

	return (
		<RaceCalendar id="race-cal">
			<Container fluid>
				<Title className="p-4">
					<h2 className="m-0">{raceCal.title}</h2>

					{/* <h3 className="m-0">
						<span>{raceCal.dateText}</span>{' '}
						<span className="text-primary">{raceCal.dates}</span>{' '}
						<span>{raceCal.location}</span>
					</h3> */}
				</Title>

				<Row>
					{raceCal.tracks.map((track, index) => (
						<Col key={index} className="g-0">
							<Fade direction="down" delay={index * 200}>
								<RaceWrapper
									className="p-4"
									style={{
										backgroundImage: `url(${tracksData.raceBackgrounds[index]})`
									}}
								>
									<SingleRace
										// date={raceCal.datesUpcoming}
										date={track.dates}
										name={track.city}
										index={index}
										number={`0${index + 1}`}
									>
										<RaceTrack
											id={`${track.city}-${index}`}
											reverse={track.reverse}
											{...tracksData.raceTrackProps[
												index
											]}
										/>
									</SingleRace>
								</RaceWrapper>
							</Fade>
						</Col>
					))}
				</Row>
			</Container>
		</RaceCalendar>
	);
};

const RaceCalendar = styled.section`
	.col {
		> div {
			height: 100%;
		}
	}

	.row {
		overflow: hidden;
	}

	@media (max-width: 1279px) {
		.row {
			display: block;
		}
	}
`;

const Title = styled.div`
	position: absolute;
	background: #fff;
	width: 60%;
	left: 0;
	z-index: 2;

	@media (max-width: 1279px) {
		position: relative;
		width: 100%;
	}

	@media ${props => props.theme.breakpoints.xs} {
		padding: 0 0.5rem 1.5rem !important;
	}
`;

const RaceWrapper = styled.div`
	height: 100%;
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: cover;

	.race-track-graphic {
		height: 65vh;
	}

	svg {
		max-width: 200px;
		height: auto;
		display: block;
		margin: auto;
		transition: all 300ms ease-in-out;
		overflow: visible;

		circle {
			opacity: 0;
		}

		path,
		circle {
			transition: opacity 0.3s ease-in-out;
		}

		@media ${props => props.theme.breakpoints.sm} {
			margin: 80px 0 40px;
		}
	}

	.u-number {
		transition: all 300ms ease-in-out;
	}

	&:hover {
		svg {
			path {
				opacity: 0.3;
			}

			circle {
				opacity: 1;
			}
		}

		.u-number {
			color: ${props => props.theme.colors.primary} !important;
			opacity: 1;
		}
	}

	@media (max-width: 1279px) {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		> div {
			order: 1;

			&:first-child {
				order: 2;
				margin: auto;
			}
		}

		svg {
			margin: 0;
		}

		.race-track-graphic {
			height: auto;
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		svg {
			max-width: 120px;
		}
	}
`;

export default RaceCal;
