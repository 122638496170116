import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';

import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import Teodosy from 'assets/team/teodosy-teodosiev.png';
import Pavel from 'assets/team/pavel-lefterov.jpg';
import Anton from 'assets/team/anton-ivanov.jpg';
import Lozan from 'assets/team/lozan-lozanov.png';
import Ivaylo from 'assets/team/ivaylo-valkanov.png';
import IvanZ from 'assets/team/ivan-zahariev.jpg';
import IvanN from 'assets/team/ivan-nikolaev.jpg';
import Yavor from 'assets/team/yavor-yankov.jpg';

const images = [Teodosy, Pavel, Anton, Lozan, Ivaylo, IvanZ, IvanN, Yavor];

const Team = () => {
	const {
		dictionary: { team }
	} = useContext(LanguageContext);

	return (
		<TeamSection>
			<Container fluid className="p-5">
				<Row>
					<Col>
						<h2 className="mb-3 mb-lg-5">{team.title}</h2>
					</Col>
				</Row>

				<Row className="d-flex justify-content-around justify-content-lg-around">
					{team.members.map((member, index) => (
						<Col xs={12} sm={6} md={6} lg={3} key={index}>
							<div className="position-relative mb-4">
								<Image className="img-fluid">
									<img
										alt={member.name}
										src={images[index]}
									/>
								</Image>

								<Info className="u-bg-black--opacity">
									<strong className="u-tx--large">
										{member.name}
									</strong>

									{member.position}
								</Info>
							</div>
						</Col>
					))}
				</Row>
			</Container>

			<Pattern x="right" y="top" name="TeamTopRight" />
		</TeamSection>
	);
};

const TeamSection = styled.section`
	position: relative;

	.position-relative {
		overflow: hidden;
		cursor: pointer;

		&:hover {
			> div {
				transform: translateY(0);
			}

			img {
				transform: translate(-50%, -50%) scale(1.05);
			}
		}
	}

	.container-fluid {
		position: relative;
		z-index: 1;

		@media ${props => props.theme.breakpoints.xs} {
			padding: 3rem 1.5rem !important;
		}
	}
`;

const Info = styled.div`
	min-height: 115px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 12px;
	color: #fff;
	transform: translateY(58px);
	transition: transform 0.3s ease-in-out;

	strong {
		display: block;
		padding-bottom: 6px;
	}

	@media ${props => props.theme.breakpoints.lg} {
		font-size: 14px;
		min-height: 104px;
		transform: translateY(53px);
	}
`;

const Image = styled.div`
	width: 100%;
	height: 0;
	position: relative;
	overflow: hidden;
	padding-bottom: 100%;

	img {
		width: 100%;
		height: auto;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1);
		transition: transform 0.3s ease-in-out;
	}
`;

export default Team;
