import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';

import { LanguageContext } from 'components/LanguageContext';

import plusIcon from 'assets/icons/plus-pr.svg';
import minusIcon from 'assets/icons/minus-bl.svg';

const FAQ = () => {
	const {
		dictionary: { faq }
	} = useContext(LanguageContext);

	return (
		<FaqSection id="faq" className="u-bg-black">
			<Container fluid>
				<Row>
					<Col
						sm={12}
						lg={{ offset: 1, span: 10 }}
						className="text-white"
					>
						<h2 className="mb-4">{faq.title}</h2>

						<Accordion>
							{faq.items.map(({ q, a }, index) => (
								<Accordion.Item key={index} eventKey={index}>
									<Accordion.Header>{q}</Accordion.Header>

									<Accordion.Body>{a}</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					</Col>
				</Row>
			</Container>
		</FaqSection>
	);
};

const FaqSection = styled.section`
	padding: 120px 0;

	.accordion {
		&-item {
			border: 0;
			background: none;
		}

		&-body {
			font-family: Montserrat, sans-serif;
		}

		&-button {
			font-size: 20px;
			color: ${props => props.theme.colors.primary};
			padding-right: 96px;
			background: url(${plusIcon}) no-repeat calc(100% - 24px) center;
			background-size: 48px 48px;
			border-radius: 0 !important;
			box-shadow: none;

			&::after {
				content: none;
			}

			&[aria-expanded='true'] {
				color: #000;
				background: ${props => props.theme.colors.primary}
					url(${minusIcon}) no-repeat calc(100% - 24px) center;
			}
		}
	}

	@media ${props => props.theme.breakpoints.sm} {
		padding: 80px 0;
	}
`;

export default FAQ;
