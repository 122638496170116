import './styles/App.scss';
import Home from './routes/home/Home';
import Meta from './components/Meta';
import { LanguageProvider } from './components/LanguageContext';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme.js';
import GlobalStyles from './styles/globalStyles.js';

function App() {
	return (
		<LanguageProvider>
			<ThemeProvider theme={theme}>
				<svg
					className="race-shadow-defs"
					style={{
						width: 0,
						height: 0,
						position: 'absolute'
					}}
				>
					<defs>
						<filter
							x="-36"
							y="-36"
							id="race-shadow"
							width="72"
							height="72"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood
								result="BackgroundImageFix"
								floodOpacity="0"
							/>

							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>

							<feMorphology
								in="SourceAlpha"
								radius="8"
								result="effect1_dropShadow_208_1441"
								operator="dilate"
							/>

							<feOffset />

							<feGaussianBlur stdDeviation="12" />

							<feComposite in2="hardAlpha" operator="out" />

							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.0745098 0 0 0 0 0.905882 0 0 0 0 0.639216 0 0 0 1 0"
							/>

							<feBlend
								in2="BackgroundImageFix"
								mode="normal"
								result="effect1_dropShadow_208_1441"
							/>

							<feBlend
								in="SourceGraphic"
								in2="effect1_dropShadow_208_1441"
								mode="normal"
								result="shape"
							/>
						</filter>
					</defs>
				</svg>

				<GlobalStyles />
				<Meta />
				<Home />
			</ThemeProvider>
		</LanguageProvider>
	);
}

export default App;
