import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import whyDriveImg from 'assets/why-drive/background.jpg';
import iconSettingsRefresh from 'assets/why-drive/icon-settings-refresh.svg';
import iconLightning from 'assets/why-drive/icon-lightning.svg';
import iconUsers from 'assets/why-drive/icon-users.svg';
import iconVolume from 'assets/why-drive/icon-volume.svg';
import iconLeaf from 'assets/why-drive/icon-leaf.svg';
import iconVector from 'assets/why-drive/icon-vector.svg';

const WhyDrive = () => {
	const {
		dictionary: { whyDrive }
	} = useContext(LanguageContext);

	return (
		<WhySection id="why-drive">
			<Container fluid>
				<Row>
					<Col sm={12} lg={5} className="g-0">
						<Fade direction="left" className="c-image">
							<Pattern
								x="left"
								y="bottom"
								name="WhyDriveBottomLeft"
							/>
						</Fade>
					</Col>

					<Col
						sm={12}
						lg={7}
						className="mt-4 mb-4 mt-lg-5 mb-lg-5 p-4 p-lg-5"
					>
						<Fade direction="right">
							<h2 className="mb-3 mb-lg-5">{whyDrive.title}</h2>

							<Row>
								<Col sm={12} lg={6}>
									<ItemWrap>
										<Icon className="c-item--settings" />
										<span>{whyDrive.ans1}</span>
									</ItemWrap>

									<ItemWrap>
										<Icon className="c-item--lightning" />
										<span>{whyDrive.ans2}</span>
									</ItemWrap>

									<ItemWrap>
										<Icon className="c-item--users" />
										<span>{whyDrive.ans3}</span>
									</ItemWrap>
								</Col>

								<Col sm={12} lg={6}>
									<ItemWrap>
										<Icon className="c-item--volume" />
										<span>{whyDrive.ans4}</span>
									</ItemWrap>

									<ItemWrap>
										<Icon className="c-item--leaf" />
										<span>{whyDrive.ans5}</span>
									</ItemWrap>

									<ItemWrap>
										<Icon className="c-item--vector" />
										<span>{whyDrive.ans6}</span>
									</ItemWrap>
								</Col>
							</Row>

							<p>{whyDrive.p1}</p>

							<p>{whyDrive.p2}</p>
						</Fade>

						<Pattern
							x="right"
							y="bottom"
							name="WhyDriveBottomRight"
						/>
					</Col>
				</Row>
			</Container>
		</WhySection>
	);
};

const WhySection = styled.section`
	position: relative;

	> .container-fluid > .row {
		overflow-x: hidden;

		> [class*='col-']:last-child {
			padding-bottom: 7rem !important;
		}
	}

	.c-image {
		height: 100%;
		background: url(${whyDriveImg}) no-repeat center center / cover;

		@media ${props => props.theme.breakpoints.md} {
			height: 50vh;
		}
	}

	@media ${props => props.theme.breakpoints.md} {
		.container-fluid > .row > [class*='col-']:last-child {
			padding-bottom: 160px !important;
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		.container-fluid > .row > [class*='col-']:last-child {
			padding-bottom: 0 !important;
		}
	}
`;

const ItemWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 24px;
`;

const Icon = styled.div`
	width: 48px;
	height: 48px;
	display: block;
	margin-right: 16px;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 48px;

	&.c-item--settings {
		background: ${props =>
			`url(${iconSettingsRefresh}) ${props.theme.colors.primary} no-repeat center center`};
		background-size: 30px 30px;
	}

	&.c-item--lightning {
		background: ${props =>
			`url(${iconLightning}) ${props.theme.colors.primary} no-repeat center center`};
		background-size: 30px 30px;
	}

	&.c-item--users {
		background: ${props =>
			`url(${iconUsers}) ${props.theme.colors.primary} no-repeat center center`};
		background-size: 30px 30px;
	}

	&.c-item--volume {
		background: ${props =>
			`url(${iconVolume}) ${props.theme.colors.primary} no-repeat center center`};
		background-size: 30px 30px;
	}

	&.c-item--leaf {
		background: ${props =>
			`url(${iconLeaf}) ${props.theme.colors.primary} no-repeat center center`};
		background-size: 30px 30px;
	}

	&.c-item--vector {
		background: ${props =>
			`url(${iconVector}) ${props.theme.colors.primary} no-repeat center center`};
		background-size: 24px 24px;
	}
`;

export default WhyDrive;
