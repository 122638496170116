import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

import CModal from 'components/Modal';
import Button from 'components/Button';
import Pattern from 'components/Pattern';
import RegForm from 'routes/home/RegForm';
import { LanguageContext } from 'components/LanguageContext';

import video from 'assets/videos/main.mp4';
import poster from 'assets/poster.png';

const Top = () => {
	const {
		dictionary: { top }
	} = useContext(LanguageContext);

	return (
		<TopSection id="top" className="d-flex">
			<Pattern x="right" y="bottom" name="IntroBottomRight" />

			<video
				src={video}
				loop
				muted
				poster={poster}
				autoPlay
				playsInline
			/>

			<Container
				fluid
				className="d-flex flex-column justify-content-center"
			>
				<Fade direction="up" triggerOnce>
					<Row>
						<Col sm={12} lg={{ span: 5, offset: 1 }}>
							<h1 className="text-white u-tx--huge text-break">
								Kinetik <br /> {top.title}
							</h1>

							<div className="d-none d-sm-block">
								<span className="text-white">
									{top.subTitle.part1}
								</span>

								<span className="text-primary">
									{top.subTitle.part2}
								</span>

								<span className="text-white">
									{top.subTitle.part3}
								</span>

								<span className="text-primary">
									{top.subTitle.part4}
								</span>
							</div>

							{/* <div>
								<span className="text-white">
									{top.officialTest.part1}
								</span>

								<span className="text-primary">
									{top.officialTest.part2}
								</span>

								<span className="text-white">
									{top.officialTest.part3}
								</span>
							</div> */}

							<CModal
								content={<RegForm />}
								className="c-modal--reg"
							>
								<Button
									variant="primary"
									className="mt-4 text-uppercase btn--hover-shadow-black"
									onClick={() => {}}
								>
									{top.cta}
								</Button>
							</CModal>
						</Col>
					</Row>
				</Fade>
			</Container>
		</TopSection>
	);
};

const TopSection = styled.section`
	height: 100vh;
	position: relative;
	overflow: hidden;

	video {
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		max-width: none;
		max-height: none;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		transform: translate(-50%, -50%);
	}

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		opacity: 0.7;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
	}

	.container-fluid {
		position: relative;
		z-index: 3;
	}

	@media ${props => props.theme.breakpoints.sm} {
		background-size: 100%, cover;
	}

	@media ${props => props.theme.breakpoints.xs} {
		background-position: 0 0, 75% 50%;
	}
`;

export default Top;
