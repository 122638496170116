// Define what props.theme will look like
export const theme = {
	colors: {
		primary: '#13E780',
		secondary: '#11d073'
	},
	breakpoints: {
		// Match react-bootstrap breakpoints
		xs: '(max-width: 576px)',
		sm: '(max-width: 768px)',
		md: '(max-width: 992px)',
		lg: '(max-width: 1200px)',
		xl: '(min-width: 1200px)'
	}
};
