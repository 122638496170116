import React, { useMemo, useState, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { Fade } from 'react-awesome-reveal';
import VisibilitySensor from 'react-visibility-sensor';

import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import electricKart from 'assets/connectors/electric-kart.png';

import { ReactComponent as Connector1 } from 'assets/connectors/1.svg';
import { ReactComponent as Connector2 } from 'assets/connectors/2.svg';
import { ReactComponent as Connector3 } from 'assets/connectors/3.svg';
import { ReactComponent as Connector4 } from 'assets/connectors/4.svg';
import { ReactComponent as Connector5 } from 'assets/connectors/5.svg';
import { ReactComponent as Connector6 } from 'assets/connectors/6.svg';

const connectors = [
	Connector1,
	Connector2,
	Connector3,
	Connector4,
	Connector5,
	Connector6
];

const WithCounter = ({ title, value, suffix, decimals = 0 }) => (
	<VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
		{({ isVisible }) => (
			<div style={{ height: 100 }}>
				<div className={`p-2 p-lg-0${isVisible ? '' : ' invisible'}`}>
					<div className="text-primary text-uppercase">{title}</div>
					<div className="u-tx--large">
						{isVisible ? (
							<CountUp
								end={value}
								decimals={decimals}
								duration={3}
							/>
						) : (
							0
						)}
						{suffix}
					</div>
				</div>
			</div>
		)}
	</VisibilitySensor>
);

const AboutElKarts = () => {
	const { dictionary } = useContext(LanguageContext);
	const { aboutElKarts } = dictionary;
	const [activeIndex, setActiveIndex] = useState(-1);

	const data = useMemo(
		() =>
			Array(6)
				.fill(0)
				.map((_, index) => ({
					text: aboutElKarts[`li${index + 1}`],
					Component: connectors[index]
				})),
		[aboutElKarts]
	);

	return (
		<AboutSection id="ego-karts" className="pt-5">
			<Pattern x="left" y="top" name="AboutKartTopLeft" />

			<Container fluid>
				<Row>
					<Col sm={12} lg={6} className="img-container p-5">
						<Fade direction="left">
							<img
								src={electricKart}
								className="img-fluid"
								alt="Electric kart"
							/>
						</Fade>
					</Col>
					<Col sm={12} lg={6} className="p-4 p-lg-5">
						<Fade
							direction="right"
							onVisibilityChange={inView =>
								!inView && setActiveIndex(-1)
							}
						>
							<h2 className="mb-3 mb-lg-5">
								{aboutElKarts.title}
							</h2>

							<List>
								{data.map(({ text, Component }, index) => (
									<li
										key={index}
										className={
											index === activeIndex
												? 'active'
												: undefined
										}
										onClick={() =>
											setActiveIndex(
												index === activeIndex
													? -1
													: index
											)
										}
									>
										{text}

										<span>
											<Component />
										</span>
									</li>
								))}
							</List>
						</Fade>
					</Col>
				</Row>
			</Container>

			<Container fluid className="pt-4 pb-4">
				<Row>
					<Col className="d-flex justify-content-around align-items-end flex-wrap flex-lg-nowrap">
						<WithCounter
							title={aboutElKarts.motorPower}
							value={40}
							suffix="kW"
						/>

						<WithCounter
							title={aboutElKarts.motorTorque}
							value={120}
							suffix="Nm"
						/>

						<WithCounter
							title={aboutElKarts.weight}
							value={115}
							suffix="kg"
						/>

						<WithCounter
							title="0-100"
							value={3.5}
							suffix="sec"
							decimals={1}
						/>

						<WithCounter
							title={aboutElKarts.topSpeed}
							value={130}
							suffix="kph"
						/>
					</Col>
				</Row>
			</Container>
		</AboutSection>
	);
};

const AboutSection = styled.section`
	position: relative;

	.img-fluid {
		max-width: 662px;
		height: auto;
		display: block;
		position: absolute;
		top: 0;
		right: 48px;
	}

	.img-container {
		position: relative;

		> div {
			height: 100%;
		}
	}

	.row {
		overflow-x: hidden;
	}

	@media ${props => props.theme.breakpoints.md} {
		.img-fluid {
			max-width: 100%;
			position: static;
			top: auto;
			right: auto;
			margin: auto;
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		.img-container {
			&,
			& + div {
				padding: 1.5rem !important;
			}
		}
	}
`;

const List = styled.ul`
	max-width: 500px;

	li {
		position: relative;
		list-style: none;
		margin-bottom: 44px;
		cursor: pointer;

		&,
		& span {
			&::before {
				content: '';
				width: 12px;
				height: 12px;
				background: ${props => props.theme.colors.primary};
				display: block;
				position: absolute;
			}
		}

		&::before {
			top: 6px;
			left: -36px;
		}

		svg {
			transform: scaleX(0);
			transition: transform 0.3s ease-in-out;
			transform-origin: 100% 0;
			display: block;
		}

		span {
			display: block;
			position: absolute;
			bottom: 100%;
			right: 100%;
			margin: 0 29px -13px 0;
			pointer-events: none;

			&::before {
				opacity: 0;
				top: -5px;
				left: 0;
				transition: opacity 0.3s ease-in-out;
				outline: ${props =>
					`6px solid ${props.theme.colors.secondary}`};
			}
		}

		&.active {
			svg {
				transform: scaleX(1);
			}

			span::before {
				opacity: 1;
				transition-delay: 0.3s;
			}
		}

		&.active,
		&:hover {
			&::before {
				outline: ${props =>
					`6px solid ${props.theme.colors.secondary}`};
			}
		}
	}

	@media (max-width: 1024px) {
		max-width: none;

		li {
			margin-bottom: 18px;
		}
	}

	@media ${props => props.theme.breakpoints.md} {
		li {
			&::before,
			&:hover::before {
				outline: none;
			}

			span {
				display: none;
			}
		}
	}
`;

export default AboutElKarts;
