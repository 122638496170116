import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import Pattern from 'components/Pattern';

import { ReactComponent as CloseIcon } from 'assets/icons/icon-close.svg';

const CModal = ({ content, className, children }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<div onClick={handleShow}>{children}</div>

			<Modal show={show} fullscreen onHide={handleClose} centered>
				<ContentWrapper>
					<Pattern
						x="left"
						y="top"
						name="RegistrationTopLeft"
						className="d-none d-lg-block d-xl-block"
					/>

					<Modal.Body className={className}>
						<CloseBtn onClick={handleClose}>
							Close <CloseIcon />
						</CloseBtn>

						{content}
					</Modal.Body>
				</ContentWrapper>
			</Modal>
		</>
	);
};

const ContentWrapper = styled.div`
	.modal-body {
		padding: 0 1rem;
	}

	.c-modal--reg {
		position: relative;
	}

	.c-modal--testimonial {
		&,
		& video {
			height: 100%;
		}

		padding: 0;

		video {
			width: 100%;
			display: block;
			position: relative;
			z-index: 1;
		}
	}

	.c-modal--race-cal {
		&,
		& .row,
		& .tab-pane,
		& .tab-content {
			height: 100%;
		}

		padding: 0;

		> button {
			z-index: 3;
		}

		.nav {
			width: 50%;
			position: absolute;
			top: 3rem;
			padding: 0 6rem;
			right: 0;
			z-index: 2;
			flex-wrap: no-wrap;

			&-link {
				font-size: 24px;
				line-height: 32px;
				color: rgba(255, 255, 255, 0.5);
				text-align: left;
				width: 100%;
				display: block;
				padding: 0 0 4px;
				border-width: 0 0 4px;
				border-style: solid;
				cursor: pointer;
				transition: color 0.3s ease-in-out;

				&:hover,
				&.active {
					color: ${props => props.theme.colors.primary};
				}
			}

			&-item {
				width: 88px;
				max-width: calc(20% - 4px);

				&:not(:first-child) {
					margin-left: 4px;
				}
			}

			@media ${props => props.theme.breakpoints.lg} {
				top: 4rem;
				padding: 0 3rem;
			}

			@media ${props => props.theme.breakpoints.md} {
				width: 100%;
				position: relative;
				top: auto;
				right: auto;
				padding: 1rem;
			}

			@media ${props => props.theme.breakpoints.xs} {
				width: 100%;
				position: relative;
				top: auto;
				right: auto;
				padding: 1rem 125px 1rem 1rem;

				&-item {
					width: calc(20% - 4px);
				}
			}
		}

		.tab-content {
			@media ${props => props.theme.breakpoints.md} {
				height: calc(100% - 72px);
			}

			.row {
				margin: 0;

				@media ${props => props.theme.breakpoints.md} {
					display: block;
				}
			}
		}
	}
`;

const CloseBtn = styled.button`
	color: ${props => props.theme.colors.primary};
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	padding: 20px;
	border: 0;
	margin: 0;
	cursor: pointer;
	background: none;
	border-radius: 0;
	box-shadow: none;
	appearance: none;

	svg {
		display: block;
		margin-left: 16px;
		transform: rotate(0deg);
		transition: transform 0.3s ease-in-out;
	}

	&:hover {
		svg {
			transform: rotate(180deg);
		}
	}
`;

export default CModal;
