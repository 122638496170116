import React from 'react';

const RaceTrack = ({ d, id, width, height, reverse, ...rest }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
	>
		<path
			d={d}
			id={`race-${id}-track`}
			fill="none"
			stroke="#13E780"
			strokeWidth={8}
			{...rest}
		/>

		<circle r={12} fill="#13E780" filter="url(#race-shadow)">
			<animateMotion
				dur="10s"
				fill="remove"
				rotate="auto"
				calcMode="linear"
				keyTimes="0;1"
				keyPoints={reverse ? '1;0' : '0;1'}
				repeatCount="indefinite"
			>
				<mpath xlinkHref={`#race-${id}-track`} />
			</animateMotion>
		</circle>
	</svg>
);

export default RaceTrack;
