import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import SignUpCustom from './SignUpCustom';

const SignUpForm = () => (
	<div className="mc__form-container">
		<MailchimpSubscribe
			url="https://kinetikautomotive.us17.list-manage.com/subscribe/post?u=38dbc54e255086278c992c812&amp;id=e649fe7cc8"
			render={({ subscribe, status, message }) => (
				<SignUpCustom
					status={status}
					message={message}
					onValidated={formData => subscribe(formData)}
				/>
			)}
		/>
	</div>
);

export default SignUpForm;
