import React, { useContext } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';

import Button from 'components/Button';
import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import sonyLogo from 'assets/partners/sony-logo.svg';
import nexoLogo from 'assets/partners/nexo-logo.svg';
import acerLogo from 'assets/partners/acer-logo.svg';
import rseatLogo from 'assets/partners/rseat-logo.svg';
import ambireLogo from 'assets/partners/ambire-logo.svg';
import devlabsLogo from 'assets/partners/devlabs-logo.svg';
import ellysiumLogo from 'assets/partners/ellysium-logo.svg';
import topMasterLogo from 'assets/partners/top-master-logo.svg';
import solarHomeLogo from 'assets/partners/solar-home-logo.svg';
import batteryCenterLogo from 'assets/partners/battery-center.svg';

const sponsorsList = [
	{
		alt: 'Nexo logo',
		src: nexoLogo,
		href: 'https://nexo.io/',
		isHigh: true
	}
];

const partnersList = [
	{
		alt: 'Top master logo',
		src: topMasterLogo,
		href: 'https://www.topmaster.bg/en'
	},
	{
		alt: 'Ellysium logo',
		src: ellysiumLogo,
		href: 'https://www.ellysium.co/'
	},
	{
		alt: 'Sony logo',
		src: sonyLogo,
		href: 'https://www.sony.com/'
	},
	{
		alt: 'Acer logo',
		src: acerLogo,
		href: 'https://www.acer.com/'
	},
	{
		alt: 'DevLabs logo',
		src: devlabsLogo,
		href: 'https://www.devlabs.bg/'
	},
	{
		alt: 'Ambire logo',
		src: ambireLogo,
		href: 'https://www.ambire.com/',
		isHigh: true
	},
	{
		alt: 'Battery Center logo',
		src: batteryCenterLogo,
		href: 'https://batterycenter.bg/'
	},
	{
		alt: 'Rseat logo',
		src: rseatLogo,
		href: 'https://www.rseat.net/',
		isHigh: true
	},
	{
		alt: 'Solar Home logo',
		src: solarHomeLogo,
		href: 'https://www.solarhome.bg/',
		isHigh: true
	}
];

const Sponsors = () => {
	const {
		dictionary: { sponsors }
	} = useContext(LanguageContext);

	const List = ({ items, title }) => (
		<>
			<Heading className="pt-3 pt-lg-5">{title}</Heading>

			<div className="c-sponsors-list">
				{items.map(({ alt, src, href, isHigh }, index) => (
					<a
						key={index}
						rel="noopener noreferrer"
						href={href}
						target="_blank"
						className="d-inline-block m-4"
					>
						<img
							src={src}
							alt={alt}
							className={`img-fluid${isHigh ? ' img-high' : ''}`}
						/>
					</a>
				))}
			</div>
		</>
	);

	return (
		<SponsorsSection>
			<Container fluid className="p-5">
				<List title={sponsors.sponsors} items={sponsorsList} />
				<List title={sponsors.partners} items={partnersList} />

				<Button
					rel="noopener noreferrer"
					href="mailto:info@kinetik-e.com"
					target="_blank"
					className="btn mt-5 mb-3 mb-lg-5 btn--hover-shadow-white"
				>
					{sponsors.cta}
				</Button>
			</Container>

			<Pattern x="left" y="top" name="SponsorsTopRight" />
		</SponsorsSection>
	);
};

const SponsorsSection = styled.section`
	background-color: #000;
	position: relative;

	.container-fluid {
		text-align: center;
		max-width: 1024px;
	}

	.c-sponsors-list {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;

		a,
		img {
			display: block;
		}
	}

	img {
		width: auto;
		height: 26px;
		display: block;

		&.img-high {
			height: 60px;
		}
	}
`;

const Heading = styled.h2`
	color: #fff;
	opacity: 0.5;
`;

export default Sponsors;
