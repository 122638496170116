import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Fade } from 'react-awesome-reveal';
import { EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import CModal from 'components/Modal';
import Button from 'components/Button';
import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import testimonial1 from 'assets/testimonials/1.jpg';
import testimonial2 from 'assets/testimonials/2.jpg';
import testimonial3 from 'assets/testimonials/3.jpg';

const images = [testimonial1, testimonial2, testimonial3];

const Testimonials = () => {
	const {
		dictionary: {
			testimonials,
			testimonials: { items }
		}
	} = useContext(LanguageContext);

	const [swiper, setSwiper] = useState(null);
	const [active, setActive] = useState(0);

	return (
		<TestimonialsSection id="testimonials">
			<Pattern x="right" y="top" name="TeamTopRight" />

			<Pattern x="right" y="bottom" name="TestimonialsBottomRight" />

			<Container fluid className="p-5">
				<Row>
					<Col xs={12} lg={4}>
						<Fade direction="left">
							<h2>{testimonials.title}</h2>

							<h5>{testimonials.subtitle}</h5>

							{items.map((item, itemIndex) => (
								<div
									key={itemIndex}
									hidden={itemIndex !== active}
								>
									{item.quote.map((line, lineIndex) => (
										<p key={lineIndex}>{line}</p>
									))}
								</div>
							))}

							<div className="buttons">
								<Button
									className="btn-sm btn--hover-shadow-black btn--reversed prev"
									onClick={() => swiper?.slidePrev()}
								>
									{testimonials.prevButton}
								</Button>

								<Button
									className="btn-sm btn--hover-shadow-black next"
									onClick={() => swiper?.slideNext()}
								>
									{testimonials.nextButton}
								</Button>
							</div>
						</Fade>
					</Col>

					<Col xs={12} lg={8}>
						<Fade direction="right">
							<Swiper
								loop
								effect="coverflow"
								modules={[EffectCoverflow]}
								breakpoints={{
									0: {
										spaceBetween: 0,
										slidesPerView: 1
									},
									768: {
										spaceBetween: 32,
										slidesPerView: 2
									}
								}}
								centeredSlides
								coverflowEffect={{
									rotate: 0,
									stretch: 20,
									depth: 100,
									modifier: 1,
									slideShadows: false
								}}
								onInit={setSwiper}
								onSlideChange={({ realIndex }) =>
									setActive(realIndex)
								}
							>
								{items.map((item, index) => (
									<SwiperSlide key={index}>
										<Image src={images[index]} />

										{!!item.video && (
											<CModal
												content={
													<video
														src={item.video}
														autoPlay
														controls
														playsInline
													/>
												}
												className="c-modal--testimonial"
											>
												<Button className="btn--hover-shadow-black">
													{testimonials.playButton}
												</Button>
											</CModal>
										)}
									</SwiperSlide>
								))}
							</Swiper>
						</Fade>
					</Col>
				</Row>
			</Container>
		</TestimonialsSection>
	);
};

const TestimonialsSection = styled.section`
	position: relative;
	padding-top: 120px;
	padding-bottom: 120px;

	.swiper {
		margin-left: 50px;
	}

	.row {
		align-items: center;
		overflow-x: hidden;
	}

	.buttons {
		margin: 0 -12px;
		padding: 4px 0;
	}

	.prev,
	.next {
		margin: 32px 12px 0;
	}

	.swiper-slide {
		.btn,
		> div {
			position: absolute;
		}

		> div {
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.btn {
			font-size: 1.5rem;
			font-weight: 400;
			position: absolute;
			top: 50%;
			left: 50%;
			padding: 1rem 2rem;
			transform: translate(-50%, -50%);
		}

		&:not(.swiper-slide-active) {
			.btn {
				display: none;
			}
		}
	}

	@media ${props => props.theme.breakpoints.md} {
		padding-top: 200px;
		padding-bottom: 200px;

		.swiper {
			margin: 32px 0 0;
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		padding: 1.5rem 0;

		.container-fluid {
			padding: 1.5rem !important;
		}
	}
`;

const Image = styled.img`
	width: 100%;
`;

export default Testimonials;
