import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import { LanguageContext } from './LanguageContext';

const Meta = () => {
	const { dictionary } = useContext(LanguageContext);
	const { meta } = dictionary;

	return (
		<Helmet>
			<title>{meta.title}</title>
			<meta name="description" content={meta.description} />
		</Helmet>
	);
};

export default Meta;
