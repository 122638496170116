import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import PropTypes from 'prop-types';

import { ReactComponent as AboutKartTopLeft } from 'assets/patterns/about-kart-top-left.svg';
import { ReactComponent as AboutUsTopLeft } from 'assets/patterns/about-us-top-left.svg';
import { ReactComponent as IntroBottomRight } from 'assets/patterns/intro-bottom-right.svg';
import { ReactComponent as ParticipationFeeBottomRight } from 'assets/patterns/participation-fee-bottom-right.svg';
import { ReactComponent as RaceCalendarTopLeft } from 'assets/patterns/race-calendar-top-left.svg';
import { ReactComponent as RegistrationTopLeft } from 'assets/patterns/registration-top-left.svg';
import { ReactComponent as SponsorsTopRight } from 'assets/patterns/sponsors-top-right.svg';
import { ReactComponent as SubscribeTopRight } from 'assets/patterns/subscribe-top-right.svg';
import { ReactComponent as TeamTopRight } from 'assets/patterns/team-top-right.svg';
import { ReactComponent as TestDriveBottomLeft } from 'assets/patterns/test-drive-bottom-left.svg';
import { ReactComponent as TestDriveFrame } from 'assets/patterns/test-drive-frame.svg';
import { ReactComponent as TestDriveTopLeft } from 'assets/patterns/test-drive-top-left.svg';
import { ReactComponent as TestimonialsBottomRight } from 'assets/patterns/testimonials-bottom-right.svg';
import { ReactComponent as WhyDriveBottomLeft } from 'assets/patterns/why-drive-bottom-left.svg';
import { ReactComponent as WhyDriveBottomRight } from 'assets/patterns/why-drive-bottom-right.svg';

const shapes = {
	AboutKartTopLeft,
	AboutUsTopLeft,
	IntroBottomRight,
	ParticipationFeeBottomRight,
	RaceCalendarTopLeft,
	RegistrationTopLeft,
	SponsorsTopRight,
	SubscribeTopRight,
	TeamTopRight,
	TestDriveBottomLeft,
	TestDriveFrame,
	TestDriveTopLeft,
	TestimonialsBottomRight,
	WhyDriveBottomLeft,
	WhyDriveBottomRight
};

export const Pattern = ({ x, y, name, className }) => {
	if (!name) {
		return null;
	}

	if (!className) {
		className = 'd-none d-sm-block';
	}

	const style = {};

	if (x && x === 'left') {
		style.left = 0;
	}

	if (x && x === 'right') {
		style.right = 0;
	}

	if (y && y === 'top') {
		style.top = 0;
	}

	if (y && y === 'bottom') {
		style.bottom = 0;
	}

	const Shape = shapes[name];

	return (
		<PatternWrapper style={style} className={`c-pattern ${className}`}>
			<Fade>
				<Shape />
			</Fade>
		</PatternWrapper>
	);
};

const PatternWrapper = styled.span`
	width: auto;
	flex: none;
	position: absolute;
	z-index: 0;
	padding: 0;
	pointer-events: none;

	svg {
		display: block;
	}
`;

Pattern.defaultProps = {
	x: 'top',
	y: 'left',
	name: ''
};

Pattern.propTypes = {
	x: PropTypes.oneOf(['left', 'right']),
	y: PropTypes.oneOf(['top', 'bottom']),
	name: PropTypes.oneOf(Object.keys(shapes))
};

export default Pattern;
