import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';

import Button from 'components/Button';
import CModal from 'components/Modal';
import Pattern from 'components/Pattern';
import { tracksData } from 'utils/tracksData';
import { LanguageContext } from 'components/LanguageContext';

const SingleRace = ({ date, name, index, number, children }) => {
	const {
		dictionary: { raceCal }
	} = useContext(LanguageContext);

	const Menu = () => (
		<Nav>
			{tracksData.raceBackgrounds.map((_, i) => (
				<Nav.Item key={i}>
					<Nav.Link eventKey={i}>{`0${i + 1}`}</Nav.Link>
				</Nav.Item>
			))}
		</Nav>
	);

	const Tabs = () => (
		<Tab.Content>
			{tracksData.raceBackgrounds.map((raceBackground, i) => {
				const track = raceCal.tracks[i];

				return (
					<Tab.Pane eventKey={i}>
						<Row>
							<Col className="g-0 d-none d-md-block">
								<StyledHalf
									style={{
										backgroundImage: `url(${raceBackground})`
									}}
								>
									<Pattern
										x="left"
										y="top"
										name="RaceCalendarTopLeft"
										className="d-none d-lg-block d-xl-block"
									/>

									<video
										src={tracksData.raceVideos[i]}
										loop
										muted
										autoPlay
										playsInline
									/>
								</StyledHalf>
							</Col>

							<Col className="text-white g-0">
								<RaceDetails>
									<h4>{track.city}</h4>

									<h5>{track.dates}</h5>

									<List className="mt-5 mb-5">
										<li>
											{raceCal.length}

											<strong>{track.length}</strong>
										</li>

										<li>
											{raceCal.width}

											<strong>{track.width}</strong>
										</li>

										<li>
											{raceCal.address}

											<a
												rel="noopener noreferrer"
												href={tracksData.locations[i]}
												target="_blank"
											>
												{track.address}
											</a>
										</li>

										<li>
											{raceCal.phone}

											<a
												rel="noopener noreferrer"
												href={`tel:${track.phoneHref}`}
											>
												{track.phone}
											</a>
										</li>

										{track.email && (
											<li>
												{raceCal.email}

												<a
													rel="noopener noreferrer"
													href={`mailto:${track.email}`}
												>
													{track.email}
												</a>
											</li>
										)}
									</List>

									<p>{raceCal.description}</p>
								</RaceDetails>
							</Col>
						</Row>
					</Tab.Pane>
				);
			})}
		</Tab.Content>
	);

	return (
		<>
			<div className="d-flex align-items-center justify-content-center race-track-graphic">
				{children}
			</div>

			<div>
				<div className="u-number u-tx--half-opacity text-white u-tx--large">
					{number}
				</div>

				<h4 className="text-white">{name}</h4>

				<span className="text-white mb-3">{date}</span>

				<CModal
					modalHeading={name}
					className="c-modal--race-cal"
					content={
						<Tab.Container defaultActiveKey={index}>
							<Menu />
							<Tabs />
						</Tab.Container>
					}
				>
					<Button
						variant="primary"
						className="mt-4 text-uppercase btn--hover-shadow-white"
					>
						{raceCal.readMore}
					</Button>
				</CModal>
			</div>
		</>
	);
};

const StyledHalf = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;

	video {
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		max-width: none;
		max-height: none;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		transform: translate(-50%, -50%);
	}

	.c-pattern {
		z-index: 2;
	}
`;

const List = styled.ul`
	li {
		position: relative;
		list-style: none;
		margin-bottom: 24px;
		color: rgba(255, 255, 255, 0.5);
	}

	li:before {
		content: '';
		width: 8px;
		height: 8px;
		background: ${props => props.theme.colors.primary};
		display: block;
		position: absolute;
		top: 8px;
		left: -24px;
	}

	a,
	strong {
		color: #fff;
		display: block;
	}

	a {
		cursor: pointer;

		&[href^='tel'] {
			cursor: text;
		}
	}

	a:not([href^='tel']):hover {
		color: ${props => props.theme.colors.primary};
	}
`;

const RaceDetails = styled.div`
	padding: 6rem 3rem 3rem;
	margin: 3rem;

	@media ${props => props.theme.breakpoints.lg} {
		margin: 3rem 0 0;
	}

	@media ${props => props.theme.breakpoints.md} {
		padding: 3rem 1rem 1rem;
		margin: 0;
	}
`;

export default SingleRace;
