import React, { useState, useEffect, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import Button from 'components/Button';
import { LanguageContext } from 'components/LanguageContext';

const RegCustom = ({ status, message, onValidated, testDriveOverwrite }) => {
	const {
		dictionary: { register }
	} = useContext(LanguageContext);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [country, setCountry] = useState('');
	const [age, setAge] = useState('');
	const [kartingLicense, setKartingLicense] = useState('Yes');
	const [testDrive, setTestDrive] = useState(
		'A championship entry only (€9,500 fee for the complete series)'
	);

	const handleSubmit = e => {
		e.preventDefault();
		email &&
			firstName &&
			lastName &&
			phoneNumber &&
			country &&
			age &&
			testDrive &&
			kartingLicense &&
			email.indexOf('@') > -1 &&
			onValidated({
				MERGE0: email,
				MERGE1: firstName,
				MERGE2: lastName,
				MERGE3: country,
				MERGE4: phoneNumber,
				MERGE5: testDrive,
				MERGE6: kartingLicense,
				MERGE7: age
			});
	};

	useEffect(() => {
		if (status === 'success') clearFields();
	}, [status]);

	const clearFields = () => {
		setFirstName('');
		setLastName('');
		setEmail('');
		setPhoneNumber('');
		setCountry('');
		setAge('');
		setKartingLicense('');
	};

	return (
		<Row className="justify-content-center mt-5 mb-5">
			<Col sm={12} lg={4} className="mt-5 mb-5">
				<div className="text-white">
					<h2 className="mb-4">
						{status === 'success'
							? register.formSuccess
							: register.formTitle}
					</h2>
					<p className="mb-4">{register.formDesc}</p>
				</div>

				<Form onSubmit={e => handleSubmit(e)}>
					{status === 'sending' && (
						<div className="mc__alert mc__alert--sending mb-3">
							{register.formSending}
						</div>
					)}

					{status === 'error' && (
						<div
							className="mc__alert mc__alert--error mb-3"
							dangerouslySetInnerHTML={{ __html: message }}
						/>
					)}

					{status === 'success' && (
						<div
							className="mc__alert mc__alert--success text-primary"
							dangerouslySetInnerHTML={{ __html: message }}
						/>
					)}

					{status !== 'success' ? (
						<>
							<Form.Group
								className="mb-4"
								controlId="formFirstName"
							>
								<Form.Control
									onChange={e => setFirstName(e.target.value)}
									type="text"
									value={firstName}
									placeholder={register.formFieldFirstName}
									required
								/>
							</Form.Group>

							<Form.Group
								className="mb-4"
								controlId="formLastName"
							>
								<Form.Control
									onChange={e => setLastName(e.target.value)}
									type="text"
									value={lastName}
									placeholder={register.formFieldLastName}
									required
								/>
							</Form.Group>

							<Form.Group
								className="mb-4"
								controlId="formBasicEmail"
							>
								<Form.Control
									onChange={e => setEmail(e.target.value)}
									type="email"
									value={email}
									placeholder={register.formFieldEmail}
									required
								/>
							</Form.Group>

							<Form.Group
								className="mb-4"
								controlId="formPhoneNumber"
							>
								<Form.Control
									onChange={e =>
										setPhoneNumber(e.target.value)
									}
									type="text"
									value={phoneNumber}
									placeholder={register.formFieldPhoneNumber}
									required
								/>
							</Form.Group>

							<Form.Group
								className="mb-4"
								controlId="formCountry"
							>
								<Form.Control
									onChange={e => setCountry(e.target.value)}
									type="text"
									value={country}
									placeholder={register.formFieldCountry}
									required
								/>
							</Form.Group>

							<Form.Group className="mb-4" controlId="formAge">
								<Form.Control
									className="u-width--80"
									onChange={e => setAge(e.target.value)}
									type="number"
									value={age}
									placeholder={register.formFieldAge}
									required
								/>
							</Form.Group>

							<Form.Group
								className="mb-4"
								controlId="formKartingLicense"
							>
								<Form.Label className="text-white">
									{register.formFieldKartingLicense}
								</Form.Label>
								<Form.Select
									required
									className="u-width--80"
									onChange={e =>
										setKartingLicense(e.target.value)
									}
								>
									<option value="Yes">
										{register.formFieldYes}
									</option>
									<option value="No">
										{register.formFieldNo}
									</option>
								</Form.Select>
							</Form.Group>

							<Form.Group
								className="mb-4"
								controlId="formTestDrive"
							>
								<Form.Label className="text-white">
									{register.formFieldDriveType}
								</Form.Label>
								<Form.Select
									required
									defaultValue={
										testDriveOverwrite || testDrive
									}
									onChange={e => setTestDrive(e.target.value)}
								>
									<option value="A championship entry only (€9,500 fee for the complete series)">
										{register.driveTypes.championshipOnly}
									</option>
									<option value="A test drive only (€250 fee)">
										{register.driveTypes.testDriveOnly}
									</option>
									<option value="Both test drive and championship">
										{register.driveTypes.both}
									</option>
								</Form.Select>
							</Form.Group>

							<div className="mb-4">
								<Button
									type="submit"
									formvalues={[
										email,
										firstName,
										lastName,
										phoneNumber,
										country,
										age,
										kartingLicense
									]}
									className="btn--hover-shadow-white"
								>
									{register.formBtn}
								</Button>
							</div>
						</>
					) : null}
				</Form>
			</Col>
		</Row>
	);
};

export default RegCustom;
