import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Fade } from 'react-awesome-reveal';
import styled, { keyframes } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay, Pagination } from 'swiper';

import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import AboutUs1 from 'assets/about-us/1.jpg';
import AboutUs2 from 'assets/about-us/2.jpg';
import AboutUs3 from 'assets/about-us/3.jpg';
import AboutUs4 from 'assets/about-us/4.jpg';
import AboutUs5 from 'assets/about-us/5.jpg';
import AboutUs6 from 'assets/about-us/6.jpg';

const images = [AboutUs1, AboutUs2, AboutUs3, AboutUs4, AboutUs5, AboutUs6];

const autoplayTime = 5000;

const fill = keyframes`
  0% {
	  width : 0%
  }

  100% {
    width: 100%;
  }


`;

const AboutUs = () => {
	const {
		dictionary: { about }
	} = useContext(LanguageContext);

	return (
		<AboutSection id="about-us">
			<Container fluid>
				<Row>
					<Col
						sm={12}
						lg={6}
						className="mt-4 mb-4 mt-lg-5 mb-lg-5 p-4 p-lg-5 text-col"
					>
						<Pattern x="left" y="top" name="AboutUsTopLeft" />

						<Fade direction="left">
							<h2 className="mb-3 mb-lg-4">{about.title}</h2>
							<div className="pb-4">{about.text1}</div>
							<p>{about.text2}</p>
						</Fade>
					</Col>

					<Col sm={12} lg={6} className="g-0 image-col">
						<Fade direction="right">
							<Swiper
								loop
								autoplay={{ delay: autoplayTime }}
								modules={[Autoplay, Pagination]}
								pagination={{
									clickable: true,
									renderBullet: (i, cls) => {
										const num = i + 1;
										const value =
											num < 10 ? `0${num}` : num;

										return `<span class="${cls}">${value}</span>`;
									}
								}}
							>
								{images.map((image, index) => (
									<SwiperSlide
										key={index}
										style={{
											backgroundImage: `url(${image})`
										}}
									/>
								))}
							</Swiper>
						</Fade>
					</Col>
				</Row>
			</Container>
		</AboutSection>
	);
};

const AboutSection = styled.section`
	position: relative;

	.row {
		overflow-x: hidden;
	}

	.text-col {
		padding-top: 13rem !important;
	}

	.image-col {
		> div {
			height: 100%;
		}
	}

	.swiper,
	.swiper-wrapper,
	.swiper-slide {
		height: 100%;
	}

	.swiper-slide {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
	}

	.swiper-pagination {
		font-family: $font-family-sans-serif;
		text-align: left;
		bottom: 0;
		right: 0;
		left: 0;
		padding: 32px 30px;

		&-bullet {
			color: rgba(255, 255, 255, 0.5);
			width: 88px;
			height: auto;
			opacity: 1;
			margin: 0 2px;
			background: none;
			border-radius: 0;

			&::after {
				content: '';
				width: 100%;
				height: 2px;
				display: block;
				background: currentColor;
				animation-fill-mode: forwards;
				animation-play-state: paused;
			}

			&-active {
				color: ${props => props.theme.colors.primary};

				&::after {
					width: 0%;
					animation-name: ${fill};
					animation-duration: ${autoplayTime}ms;
					animation-timing-function: linear;
					animation-iteration-count: 1;
					animation-play-state: running;
				}
			}
		}
	}

	@media ${props => props.theme.breakpoints.md} {
		.image-col {
			> div {
				height: 50vh;
			}
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		.text-col {
			padding: 1.5rem !important;
		}

		.swiper-pagination {
			padding: 1.5rem;
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;

			&-bullet {
				flex: 1;
				width: auto;
			}
		}
	}
`;

export default AboutUs;
