import React from 'react';
import BootstrapButton from 'react-bootstrap/Button';

import { ReactComponent as ButtonShape } from 'assets/icons/button-arrows.svg';

export const Button = ({ children, ...rest }) => (
	<BootstrapButton {...rest}>
		{children}

		<span>
			<ButtonShape />
		</span>
	</BootstrapButton>
);

export default Button;
